import React, { useEffect, useRef, useState } from 'react';



const MarcatoreQuotaTabellaBF = (props) => {
    const { quota } = props;
    const { quota_2 } = props;
    const [stile, setStile] = useState('bg-transparent');
    const [stileFreccia, setStileFreccia] = useState('');
    const prevQuota = usePrevious({quota});
    // variabile per gestire l'apertura del componente se visualizzare anche la quota 2 o meno
    const [open, setOpen] = useState(false);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    useEffect(() => {
        if(prevQuota && prevQuota.quota !== quota && quota > 1) {
            if (prevQuota.quota>quota) {
                setStileFreccia('bi bi-arrow-down text-danger');
                setStile('bg-info text-white');
            } else {
                setStileFreccia('bi bi-arrow-up text-success');
                setStile('bg-info text-white');
            }           
            setTimeout(() => {
                setStile('quota-flash'); 
            }, 1000);
        }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quota]);
  
    return (
        <>  
            <div className={"position-relative " + stile} title={props.title} onClick={()=>setOpen(!open)}>
                { open ?
                        <div className='d-flex flex-row'>
                            <div className={"position-relative "} style={{float: "left", width: "33px", backgroundColor: "#a6d8ff", textWrap: 'nowrap', overflow: 'hidden'}}>
                            <i className={"position-absolute "}></i>
                            {quota_2 > 1 ? parseFloat(quota_2).toFixed(2) : '-'}
                            </div>
                            <div className={"position-relative "} style={{float: "right", width: "33px", background: "#f694aa", textWrap: 'nowrap', overflow: 'hidden'}}>
                                <i className={"position-absolute "}></i>
                                {quota > 1 ? parseFloat(quota).toFixed(2) : '-'}
                            </div>
                        </div>
                        :
                        <div>
                                <i className={"position-absolute " + stileFreccia} style={{left: "5px", top: "-1px"}}></i>
                                {quota > 1 ? parseFloat(quota).toFixed(2) : '-'}        
                        </div>
                }
            </div>
        </>
    );
}

export default MarcatoreQuotaTabellaBF;