import { ServersConst, MarcatoriConst } from '../../constants';
import { alertBarInfoEnum } from '../../redux/marcatori/constants';



export const checkEventoAlert = (evento_id, quote, distanza, percentuale, concessionari_da_non_considerare = [], limite_quota_media = 1000, concessionari_non_visualizzati = []) => {

    // BETFAIR CHECK
    var dizionario_evento = quote[evento_id]
    Object.keys(dizionario_evento).map((quota_id) => {
        if (MarcatoriConst.CONCESSIONARIO_BETFAIR in dizionario_evento[quota_id]) {
            Object.keys(dizionario_evento[quota_id]).map((concessionario_codice) => {
                if (!MarcatoriConst.CONFRONTO.includes(concessionario_codice)) {
                    dizionario_evento[quota_id][concessionario_codice]['alert'] = checkQuotaAlertBF(evento_id, quota_id, concessionario_codice, quote, distanza)
                }
            })
        }
    })

    //QUOTA_MEDIA CHECK
    Object.keys(dizionario_evento).map((quota_id) => {
        var marcatore = dizionario_evento[quota_id]
        dizionario_evento[quota_id] = checkQuotaMediaFunc(marcatore, percentuale, concessionari_da_non_considerare, limite_quota_media, concessionari_non_visualizzati)
        /*if(concessionario){
            if(!('alert' in marcatore[concessionario])){
                marcatore[concessionario]['alert'] = 0;
            }
            if(concessionario != 'BETFAIR'){
                marcatore[concessionario]['alert'] = marcatore[concessionario]['alert'] + 2
                dizionario_evento[quota_id] = marcatore
            }
        }*/
    })


    return dizionario_evento
}

export const checkQuotaAlertBF = (evento_id, quota_id, concessionario_codice, quote, distanza) => {
    if (!MarcatoriConst.CONFRONTO.includes(concessionario_codice)) {
        if (!('alert' in quote[evento_id][quota_id][concessionario_codice])) {
            quote[evento_id][quota_id][concessionario_codice]['alert'] = 0;
        }
        if (quote[evento_id][quota_id][concessionario_codice]['alert'] & MarcatoriConst.VALUE_ALERT_BF) {
            if (((parseFloat(quote[evento_id][quota_id][concessionario_codice]['value']) + parseFloat(distanza)) < parseFloat(quote[evento_id][quota_id][MarcatoriConst.CONCESSIONARIO_BETFAIR]['value'])) || parseFloat(quote[evento_id][quota_id][MarcatoriConst.CONCESSIONARIO_BETFAIR]['value']) <= 1) {
                quote[evento_id][quota_id][concessionario_codice]['alert'] -= MarcatoriConst.VALUE_ALERT_BF
            }
        } else {
            if (MarcatoriConst.CONCESSIONARIO_BETFAIR in quote[evento_id][quota_id] && parseFloat(quote[evento_id][quota_id][MarcatoriConst.CONCESSIONARIO_BETFAIR]['value']) > 1) {
                if ((parseFloat(quote[evento_id][quota_id][concessionario_codice]['value']) + parseFloat(distanza)) >= parseFloat(quote[evento_id][quota_id][MarcatoriConst.CONCESSIONARIO_BETFAIR]['value'])) {
                    quote[evento_id][quota_id][concessionario_codice]['alert'] += MarcatoriConst.VALUE_ALERT_BF
                }
            }
        }
        return quote[evento_id][quota_id][concessionario_codice]['alert']
    }

}

export const checkQuotaMediaFunc = (marcatore, percentuale, concessionari_da_non_considerare = [], limite_quota_media = 1000, concessionari_non_visualizzati = [], alert_attivato = [false]) => {
    const num_minimo_quote = 3
    var somma = 0
    var number = 0
    var maxOdd = 0
    // dizionario che cotiene i valori dei precedenti alert come chiave avremo il concessionario relativo questo sarà utile per valutare se l'alert è 
    // scattato o meno
    var old_alerts = {}
    Object.keys(marcatore).map((concessionario_codice) => {
        if ('value' in marcatore[concessionario_codice] && parseFloat(marcatore[concessionario_codice]['value']) > 0 && !MarcatoriConst.CONFRONTO.includes(concessionario_codice) && !concessionari_da_non_considerare.includes(concessionario_codice)) {
            if (maxOdd < parseFloat(marcatore[concessionario_codice]['value']) && !concessionari_non_visualizzati.includes(concessionario_codice)) {
                maxOdd = parseFloat(marcatore[concessionario_codice]['value'])
            }
            somma = somma + parseFloat(marcatore[concessionario_codice]['value'])
            number++
        }
    })
    Object.keys(marcatore).map((concessionario_codice) => {
        if ('alert' in marcatore[concessionario_codice]) {
            old_alerts[concessionario_codice] = marcatore[concessionario_codice]['alert']
            if (marcatore[concessionario_codice]['alert'] & MarcatoriConst.VALUE_ALERT_AVG) {
                marcatore[concessionario_codice]['alert'] = parseFloat(marcatore[concessionario_codice]['alert']) - MarcatoriConst.VALUE_ALERT_AVG
            }
        } else {
            marcatore[concessionario_codice]['alert'] = 0
            old_alerts[concessionario_codice] = marcatore[concessionario_codice]['alert']
        }
    })
    if (somma > 0 && number >= num_minimo_quote) {
        var media = 0
        var diff = 0
        var concessionari_return = []
        Object.keys(marcatore).map((concessionario_codice) => {
            if (!MarcatoriConst.CONFRONTO.includes(concessionario_codice)) {
                if (concessionario_codice === MarcatoriConst.CONCESSIONARIO_VIRTUALE) {
                    media = (maxOdd === parseFloat(marcatore[concessionario_codice]['value']) ? (somma - marcatore[concessionario_codice]['value']) / (number - 1) : somma / number)
                } else {
                    // se il concessionario codice non è presente in quelli da non considerare allora alla somma totale elimino la sua quota e ricalcolo la media
                    media = (!concessionari_da_non_considerare.includes(concessionario_codice) ? (somma - marcatore[concessionario_codice]['value']) / (number - 1) : somma / number)
                }
                diff = marcatore[concessionario_codice]['value'] - media
                // ora la percentuale non sarà un valore razionale [0.5 -> 50%, 1.5 -> 150%] ma sarà un valore di percentuale esatta [50%, 60%] 
                // quindi per non cambiare la logica basta dividerlo per 100 
                if (diff >= (media * (percentuale / 100))) {
                    concessionari_return.push(concessionario_codice)

                }
            }
        })
    }
    if (concessionari_return) {
        concessionari_return.forEach((concessionario_return) => {
            if (!('alert' in marcatore[concessionario_return])) {
                marcatore[concessionario_return]['alert'] = 0;
            }

            if (!MarcatoriConst.CONFRONTO.includes(concessionario_return) && parseFloat(marcatore[concessionario_return]['value']) <= parseFloat(limite_quota_media)) {
                marcatore[concessionario_return]['alert'] = marcatore[concessionario_return]['alert'] + MarcatoriConst.VALUE_ALERT_AVG
            }
            // vedo se l'alert relativo al suono è stato attivato, quindi controllo anche se il concessionario è correttamente visualizzato
            alert_attivato[0] = Boolean(
                !alert_attivato[0]
                && !(old_alerts[concessionario_return] & MarcatoriConst.VALUE_ALERT_AVG)
                && (marcatore[concessionario_return]['alert'] & MarcatoriConst.VALUE_ALERT_AVG)
                && !concessionari_non_visualizzati.includes(concessionario_return)
            ) || alert_attivato[0]

        })
    }

    return marcatore
}

// recupera lo stato degli alert
export function getAlert(quote) {
    let alerts = [];

    Object.keys(quote).map((concessionario) => {
        if ((quote[concessionario].alert & MarcatoriConst.VALUE_ALERT_AVG) == MarcatoriConst.VALUE_ALERT_AVG) {
            alerts.push({'concessionario': concessionario, alert: MarcatoriConst.VALUE_ALERT_AVG});
        }
        if ((quote[concessionario].alert & MarcatoriConst.VALUE_ALERT_BF) == MarcatoriConst.VALUE_ALERT_BF) {
            alerts.push({'concessionario': concessionario, alert: MarcatoriConst.VALUE_ALERT_BF});
        }
    })

    return alerts;
}

// compara due dizionari, recuperando gli elementi del primo che non si trovano nel secondo
export function compareAlert(alerts_One, alerts_Two) {

    let alerts = [];

    alerts_One.forEach(itemOne => {
        let trovato = false;
        for (const itemTwo of alerts_Two) {
            if(itemOne.concessionario == itemTwo.concessionario && itemOne.alert == itemTwo.alert)
            {
                trovato = true;
                break;
            }
        }
        if(!trovato) alerts.push(itemOne);
    });

    return alerts;

}

// calco percentuale guadagno
export function calcoloGuadagno(banca, punta) {
    if (banca > 0 && punta > banca) {
        return ((punta - banca) / banca) * 100;
    }
    return 0;
}

// genera l'id per il singolo alert
export function generateIDAlert(alert) {
    let id = `${alert['evento_id']}-${alert['tipo_alert']}`;
    alert.concessionari.map((concessionario) => {
        let cId = `${concessionario.codice}#${alert.quota_id}`;
        id += `-${cId}`;
    });

    return id;
}


/**
 * Gestione del sorting degli alert
 * Si basa sullo stato globale sortAlertBarInfo contenete le informazioni per il sorting
 * @param {*} tempAlertsBar stato temporaneo degli alert
 * @param {*} state stato dall'applicazione
 * @returns Stato temporaneo degli alert ordinato
 */
export function sortingAlertBar(tempAlertsBar, state) {
    if (state.sortAlertBarInfo.sorting == alertBarInfoEnum.PERCENTUALE_GUADAGNO) {
        if (state.sortAlertBarInfo.order == alertBarInfoEnum.CRESCENTE) {
            tempAlertsBar = tempAlertsBar.sort(function (a, b) { return a.percentuale_guadagno - b.percentuale_guadagno })
        } else if (state.sortAlertBarInfo.order == alertBarInfoEnum.DECRESCENTE) {
            tempAlertsBar = tempAlertsBar.sort(function (a, b) { return b.percentuale_guadagno - a.percentuale_guadagno })
        }
    } else if (state.sortAlertBarInfo.sorting == alertBarInfoEnum.TIMESTAMP) {
        if (state.sortAlertBarInfo.order == alertBarInfoEnum.CRESCENTE) {
            tempAlertsBar = tempAlertsBar.sort(function (a, b) {
                return b.timestamp - a.timestamp;
            })
        } else if (state.sortAlertBarInfo.order == alertBarInfoEnum.DECRESCENTE) {
            tempAlertsBar = tempAlertsBar.sort(function (a, b) {
                return a.timestamp - b.timestamp;
            })
        }
    }
    return tempAlertsBar;
}
