import React, { useState } from 'react';
import { Table, Row, Col, Dropdown} from 'react-bootstrap'
import  {useDispatch} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import './index.css'
import RowMarcatore from './RowMarcatore';
import * as MarcatoriActions from '../../redux/marcatori/actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { MarcatoriConst } from '../../constants';


const RowEvento = (props) => {
    const classNames = require('classnames')
    //const [isOpen, setIsOpen] = useState(props.eventiVisibility)

    const [marcatoriVisibility, setMarcatoriVisibility] = useState(props.marcatoriVisibility)
    const evento_id = props.evento.evento_id
    const dispatch = useDispatch()

    const classColumns = classNames(
      {
        'col-12': props.visualizzazione == MarcatoriConst.VISUALIZZAZIONE_1_COLONNA,
        'col-6': props.visualizzazione & MarcatoriConst.VISUALIZZAZIONE_2_COLONNE,
        'col-4': props.visualizzazione & MarcatoriConst.VISUALIZZAZIONE_3_COLONNE,
      }
    );

    // useEffect(() => {
    //   setIsOpen(props.eventiVisibility)
    // }, [props.eventiVisibility])

    // useEffect(() =>{
    //   setMarcatoriVisibility(props.marcatoriVisibility)
    // }, [props.marcatoriVisibility])

    return(
      props.evento.visible && props.evento.enabled &&
        <>
        <Row  className="bg-dark text-light border-row">
        <Col>
        <Table className="table-borderless bg-dark text-light p-0 mb-0">
        <thead>
              <tr>
                <th>
                <div className='fs-descrizione-evento'>
                  <Dropdown style={{float: "left", marginLeft: "-15px", marginRight: "5px"}}>
                    <Dropdown.Toggle id='dropdown-basic' variant={props.evento.quote_errore? 'danger': 'dark'} size='sm'></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick = {()=>{
                          dispatch(MarcatoriActions.setStateOpenEvento(!props.evento.open, props.evento));
                        }}                      
                      >
                        {props.evento.open ? "Compatta Evento" : "Espandi Evento"}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={props.evento.errore_quote? 'bg-danger': ''}
                        onClick={()=>{
                          dispatch(MarcatoriActions.getQuoteEvento(props.evento.evento_id, props.concessionari, props.specialita))
                          setTimeout(() => {
                          }, 1000);
                        }}
                      >
                        Aggiorna Quote
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={()=>{
                          confirmAlert({
                            title: '',
                            message: 'Sicuro di voler eliminare questo evento?',
                            buttons: [
                              {
                                label: 'Si',
                                onClick: () => dispatch(MarcatoriActions.setStateEnabledEvent(props.evento, false))

                              },
                              {
                                label: 'No',
                              }
                            ]
                          });
                        }} 
                      >
                      Rimuovi Evento
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div style={{float: "left"}}>
                    <span style={{ userSelect: "none", cursor: "pointer"}} onClick = {()=>{
                          dispatch(MarcatoriActions.setStateOpenEvento(!props.evento.open, props.evento));}} > 
                      {props.evento.descrizione} 
                    </span>
                    <div className='fs-info-evento' style={{ userSelect: "none"}}>
                      {props.evento.data + ' | ' + props.evento.campionato_descrizione}
                    </div>
                  </div>
                </div>
                </th>
                <th>
                  <Table  align={'right'} className='table-borderless mb-0 w-auto' >
                    <tbody>
                      <tr>
                        <td width={150}   className='align-middle fs-quote' align={'right'}>

                        </td>
                        
                        {/* Visualizzazione delle specialita vicino al nome dell'evento*/}
                        {/* {classColumns == 'col-12' && props.specialita && props.specialita.map((spec)=>
                            spec.visible && <td key={spec.id + evento_id} className='text-center align-middle fs-quote text-center text-light' width={70} >{spec.descrizione}</td>
                          )} */}
                      </tr>
                    </tbody>
                  </Table>
                </th>
              </tr>
            </thead>
        </Table>
        </Col>
        </Row>
        {props.evento.open && <div  className="row bg-secondary">
          {props.evento.marcatori && props.evento.marcatori.map((marcatore)=>
                <RowMarcatore key={evento_id+marcatore.quota_descrizione} alert_abilitati = {props.alert_abilitati} visibilitaMarcatore={marcatoriVisibility} marcatore={marcatore} evento={props.evento} concessionari={props.concessionari} specialita={props.specialita} quote={props.quote} evento_id={props.evento.evento_id} classColumns={classColumns} tipoVisualizzazione={props.visualizzazione} rowHeaderOpen={props.rowHeaderOpen}/>
          )}
          
        </div>
        }
     </>
    );
}

export default RowEvento;