import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { MarcatoriConst } from '../../constants';
import 'bootstrap-icons/font/bootstrap-icons.css';
import * as MarcatoriActions from '../../redux/marcatori/actions';
import { getConcessionariNotQuotaMedia } from '../../utils/marcatori/odds_function';

// Rappresenta il singolo alert visualizzato all'interno della barra a destra di Live.js
function Alert(Props) {

    // Colori usati nei vari stili
    const percMinBackground = "#495057";
    const intestazioneBackground = "#98d9dd";
    const intestazioneMediaBackground = "rgb(255 204 52)";
    const concRifBackground = "#E4F0F9";
    const concNonRifBackground = "infoBackground";

    const percMinColor = "#FFFFFF";
    const timerColor = "#f1e6e6";
    const cestinoColor = "#f1e6e6";
    const concMercColor = "#0D47A1";
    const quotaColor = "#002761";
    const campionatoColor = "#221d1d";

    // stili inerenti alle info dell'evento
    const percentualeGuadagnoColStyle = {
        backgroundColor: percMinBackground
    }

    const percentualeGuadagnoStyle =
    {
        fontSize: "14px",
        textWrap: "nowrap",
        overflow: "hidden",
        color: percMinColor
    }

    const eventoTitoloColStyle = {
        backgroundColor: Props.avviso.tipo_alert == MarcatoriConst.VALUE_ALERT_AVG ? intestazioneMediaBackground : intestazioneBackground,
        overflow: "hidden"
    }

    const eventoTitoloStyle =
    {
        fontSize: "13px",
        textWrap: "nowrap",
        overflow: "hidden",
        fontWeight: "700",
    }

    const dataInizioCampionatoStyle = {
        fontSize: "11px",
        overflow: "hidden",
        textWrap: "nowrap",
    }


    const timeStampAlertColStyle = {
        backgroundColor: percMinBackground
    }

    const timerStyle = {
        color: timerColor
    }

    const timeStampAlertStyle = {
        fontSize: "13px",
        textWrap: "nowrap",
        overflow: "hidden",
        color: percMinColor,
    }

    const cestinoStyle = {
        cursor: "pointer",
        color: cestinoColor,
        fontSize: "13px"
    }

    const CampionatoColStyle = {
        backgroundColor: intestazioneBackground,
    }

    const campionatoStyle = {
        color: campionatoColor,
        cursor: "pointer"
    }

    // stili inerenti ai concessionari
    const concessionarioInfoStyle = {
        fontSize: "12px",
        textWrap: "nowrap",
        overflow: "hidden",
        color: concMercColor
    }

    const concessionarioInfoQuotaDescrizioneStyle = {
        fontSize: "12px",
        textWrap: "nowrap",
        color: concMercColor
    }

    const concessionarioInfoEventoStyle = {
        fontSize: "12px",
        textWrap: "nowrap",
        overflow: "hidden",
        color: "black"
    }

    const concessionarioQuotaStyle = {
        fontSize: "12px",
        textWrap: "nowrap",
        overflow: "hidden",
        fontWeight: "900",
        color: quotaColor
    }

    const concessionarioMediaStyle = {
        fontSize: "12px",
        textWrap: "nowrap",
        overflow: "hidden",
        fontWeight: "900",
        color: quotaColor
    }


    // stili inerenti ai risultati
    const risultatiColStyle =
    {
        backgroundColor: "green",
    }

    const risultatiStyle =
    {
        fontSize: "13px",
        color: "white"
    }

    // Stili inerenti al minuto/set/basket

    const minutiColStyle = {
        backgroundColor: "black",
        borderLeft: "1px solid #999",
        padding: "0 7px 0 7px"
    }

    const minutiStyle = {
        fontSize: "14px",
        color: "white"
    }

    const setBasColStyle = {
        backgroundColor: "black"
    }

    const setBasDivStyle =
    {
        borderLeft: "1px solid #999",
        padding: "0 7px 0 7px"
    }

    const setBasStyle = {
        fontSize: "13px",
        color: "white"
    }


    const dispatch = useDispatch();

    // Formattazione del timestamp generale in secondi, minuti e ore 
    function timeStampParse() {
        let timeStampDiff = Date.now() - Props.avviso.timestamp;
        let secondi = Math.floor(timeStampDiff / 1000);
        let minuti = Math.floor(secondi / 60);
        let ore = Math.floor(minuti / 60);

        if (ore > 0) {
            return `${ore} ore`;
        } else if (minuti > 0) {
            return `${minuti} min`;
        } else {
            return `${secondi} sec`;
        }
    }

    // calcolo della media nel caso di alert 2
    function calcolaMedia() {
        let concessionari_da_non_contare = getConcessionariNotQuotaMedia(Props.concessionari);

        // escludo il proprio concessionario dal calcolo 
        for (const concessionario of Props.avviso.concessionari) {
            concessionari_da_non_contare.push(concessionario.codice);
        }

        let quota = 0;
        let sommaQuote = 0;
        let elementi = 0;
        Object.keys(Props.quote[Props.avviso.evento_id][Props.avviso.quota_id]).map((concessionario) => {
            if ('value' in Props.quote[Props.avviso.evento_id][Props.avviso.quota_id][concessionario] && parseFloat(Props.quote[Props.avviso.evento_id][Props.avviso.quota_id][concessionario]['value']) > 0 &&
                !MarcatoriConst.CONFRONTO.includes(concessionario) && !concessionari_da_non_contare.includes(concessionario)) {
                sommaQuote += parseFloat(Props.quote[Props.avviso.evento_id][Props.avviso.quota_id][concessionario]['value']);
                elementi++;
            }
        })

        if (elementi > 0) {
            quota = Number(sommaQuote / elementi);
        }

        return quota;
    }

    // Recupero della descrizione collegata alla specialità
    function getSpecialita() {
        for (const evento of Props.eventi) {
            for (const marcatore of evento.marcatori) {
                for (const key in marcatore.specialita) {
                    if (marcatore.specialita[key] == Props.avviso.quota_id) {
                        let specialita_id = key;
                        if (specialita_id != undefined) {
                            for (const specialita of Props.specialita) {
                                if (specialita.id == specialita_id) {
                                    return specialita.descrizione;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // Recupero del nome dell'evento
    function getEvento() {
        for (const evento of Props.eventi) {
            if (evento.evento_id == Props.avviso.evento_id) {
                return evento.descrizione;
            }
        }
    }

    // Recupero della data se disponibili
    function getData()
    {
        let data = "";
        for (const evento of Props.eventi) {
            if (evento.evento_id == Props.avviso.evento_id) {
                if (evento.data) {
                    data = evento.data;
                }
                break;
            }
        }

        return data;
    }

    // recupera il nome del campionato
    function getCampionato()
    {
        let campionato_nome = "";
        for (const evento of Props.eventi) {
            if (evento.evento_id == Props.avviso.evento_id) {
                if (evento.campionato_id) {
                    let campionato_id = evento.campionato_id;

                    for (const campionato of Props.campionati) {
                        if (campionato.campionato_id == campionato_id) {
                            if (campionato.campionato_descrizione) {
                                campionato_nome = campionato.campionato_descrizione;
                            }
                            break;
                        }
                    }                   
                }
                break;
            }
        }

        return campionato_nome;
    }

    return (
        <Container style={{
            maxWidth: "600px",
        }}>
            {/*Recupero delle informazioni generali collegate all'evento*/}
            <Row className='border-bottom border-top border-dark'>
                <Col className='col-2 d-flex justify-content-center align-items-center p-0' style={percentualeGuadagnoColStyle} title="Percentuale Guadagno">
                    <div style={percentualeGuadagnoStyle}>{(Props.avviso.tipo_alert == MarcatoriConst.VALUE_ALERT_AVG) ? "-" : (Props.avviso.percentuale_guadagno >= 100) ? Props.avviso.percentuale_guadagno.toFixed(0)+"%" : Props.avviso.percentuale_guadagno.toFixed(2)+"%"}</div>
                </Col>
                <Col className='col-8 d-flex flex-row ps-0 pe-0'>
                    <Col className="ps-1" style={eventoTitoloColStyle}>
                        <div style={eventoTitoloStyle}>{getEvento()}</div>
                        <div style={dataInizioCampionatoStyle} title={getCampionato()}>{getData()} {getCampionato()}</div>
                    </Col>
                </Col>
                <Col className='col-2 d-flex justify-content-center align-items-center p-0' style={timeStampAlertColStyle} title="Tempo Trascorso">
                    <Row>
                        <Col className='col-10 p-0'>
                            <div style={timeStampAlertStyle}>{timeStampParse()}</div>
                        </Col>
                        <Col className='col-2 d-flex justify-content-center align-items-center p-0'>
                            <i className="bi bi-trash3" style={cestinoStyle} onClick={() => dispatch(MarcatoriActions.removeAlert(Props.avviso.Id))
                            }></i>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {
                /*Recupero delle informazioni del singolo concessionario*/
                Props.avviso.concessionari.map((concessionarioAlert, indice) => {

                    let concessionario = concessionarioAlert.codice;

                    let specialita = getSpecialita();

                    return (<Row key={Props.avviso.id + '-' + concessionario} className='p-2' style={{
                        backgroundColor: (indice == 0) ? concRifBackground : concNonRifBackground,
                    }}>
                        <Col className='col-3 p-0 d-flex justify-content-center align-items-center'>
                            <div style={concessionarioInfoStyle}>{concessionarioAlert.descrizione}</div>
                        </Col>
                        <Col className='col-4 pe-0'>
                            <div style={concessionarioInfoEventoStyle} title={Props.quote[Props.avviso.evento_id][Props.avviso.quota_id][concessionario].quota_descrizione}>
                                {Props.quote[Props.avviso.evento_id][Props.avviso.quota_id][concessionario].quota_descrizione}
                            </div>
                        </Col>
                        <Col className='col-3 d-flex justify-content-center align-items-center'>
                            <div style={concessionarioInfoQuotaDescrizioneStyle} title={specialita}>{specialita}</div>
                        </Col>
                        <Col className='col-2 d-flex justify-content-end align-items-center p-0'>
                            <div style={concessionarioQuotaStyle}>{Number(Props.quote[Props.avviso.evento_id][Props.avviso.quota_id][concessionario].value).toFixed(2)}</div>
                        </Col>
                    </Row>)
                })
            }

            {
                (Props.avviso.tipo_alert == MarcatoriConst.VALUE_ALERT_AVG) ?
                    <Row className='p-2' style={{
                        backgroundColor: concNonRifBackground,
                    }}>
                        <Col className='col-7'>
                        </Col>
                        <Col className='col-3 d-flex justify-content-center'>
                            <div style={concessionarioInfoQuotaDescrizioneStyle}>Quota Media</div>
                        </Col>
                        <Col className='col-2 d-flex justify-content-end pe-1'>
                            <div style={concessionarioMediaStyle}>{calcolaMedia().toFixed(2)}</div>
                        </Col>
                    </Row>
                    : ""
            }
        </Container>
    )
}
export default Alert;