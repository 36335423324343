import { MarcatoriConst } from "../../constants"

export  const getMaxOdds = (odds_conc, concessionari_da_non_contare = []) => {
   var value = 0
    Object.keys(odds_conc).map((key)=>{
        if(!MarcatoriConst.CONFRONTO.includes(key) && key !== MarcatoriConst.CONCESSIONARIO_VIRTUALE){
            if(!concessionari_da_non_contare.includes(key)){
                if(parseFloat(odds_conc[key]['value'])>value){
                    value = odds_conc[key]['value']
                }
            }
        }
        
   })
   return value
}

export  const getMaxOddsConc = (odds_conc, concessionari_da_non_contare = []) => {
  let concessionario;
  let value = 0;
   Object.keys(odds_conc).map((key)=>{
       if(!MarcatoriConst.CONFRONTO.includes(key) && key !== MarcatoriConst.CONCESSIONARIO_VIRTUALE){
           if(!concessionari_da_non_contare.includes(key)){
             if (parseFloat(odds_conc[key]['value']) > value) {
               value = odds_conc[key]['value'];
               concessionario = key;
             }
           }
       }
       
  })
  return concessionario
}

export const getConcessionariAvvisoVisible = (lista_concessionari) => {
  let concessionari = [];
  for (const concessionario of lista_concessionari) {
    if (concessionario.avvisi_visible) {
      concessionari.push(concessionario.concessionario_codice);
    }
  }
  return concessionari;
}

export const getConcessionariVisible = (lista_concessionari) => {
  let concessionari = [];
  for (const concessionario of lista_concessionari) {
    if (concessionario.visible) {
      concessionari.push(concessionario.concessionario_codice);
    }
  }
  return concessionari;
}

export const getEventiVisibili = (eventi) => {
  var eventi_visualizzati = []
  eventi.forEach(evento => {
    if(evento.visible && evento.enabled){
      eventi_visualizzati.push(evento);
    }
  })
  return eventi_visualizzati;
}

export const getConcessionariNonVisualizzati = (lista_concessionari) => {
    var concessionari_da_non_contare = []
    lista_concessionari.forEach((concessionario)=>{
        if(!concessionario.visible){
          concessionari_da_non_contare.push(concessionario.concessionario_codice)
        }
      })

    return concessionari_da_non_contare
}

// ritorna l'elenco dei concessionari per i quali non deve essere valutata la quota media 
export const getConcessionariNotQuotaMedia = (lista_concessionari) => {
  var concessionari_da_non_contare = []
  lista_concessionari.forEach((concessionario)=>{
      if(!concessionario.valuta_quota_media){
        concessionari_da_non_contare.push(concessionario.concessionario_codice)
      }
    })
  return concessionari_da_non_contare
  }

export const getCampionatiVisualizzati = (lista_campionati) => {
    var campionati_visualizzati = []
    lista_campionati.forEach((campionato)=>{
        if(campionato.visible){
            campionati_visualizzati.push(campionato.campionato_id)
        }
      })

    return campionati_visualizzati

}

export const getSpecialitaAttive = (lista_specialita) => {
  var specialita_visualizzate = []
  lista_specialita.forEach(specialita => {
    if(specialita.visible){
      specialita_visualizzate.push(parseInt(specialita.id));
    }
  })
  return specialita_visualizzate
}

//Controlla se la specialità collegata alla nostra quota è visibile
export const getSpecialitaAttivaByQuota = (evento, quota_id, lista_specialita) =>
{
  for (const marcatore of evento.marcatori) {
    for (const specialita in marcatore.specialita) {
      if (marcatore.specialita[specialita] == quota_id) {
        return getSpecialitaAttive(lista_specialita).includes(parseInt(specialita));
      }
    }
  }
}

export const cleanQuote = (evento_id, quote_list) => {
    // funzione che permette di rimuovere un intero evento e le sue quote dall'insieme delle quote presenti
    if(evento_id in quote_list){
        delete quote_list[evento_id]
    }
    return quote_list
}

export const getFilterDatesByType = (tipo) => {
    var start_date = 1
    var end_date = 1
    var get_all = false
    switch(tipo){
        case 0:
          start_date = (new Date()).getTime()/1000
          end_date = (new Date()).setHours(23, 59,59)/1000
          break
        case 1:
          start_date = (new Date()).getTime()/1000
          end_date = new Date()
          let actual_day = end_date.getDate()
          end_date.setHours(23, 59,59)
          end_date.setDate(actual_day + 1)
          end_date = end_date.getTime()/1000
          break
        case 2:
          get_all = true
          break
      }
    return {'start_date': start_date, 'end_date': end_date, 'get_all': get_all}
}


// se quote_id sarà 0 allora si controlleranno tutte le quote
// se quote_id è valorizzato allora si controlleranno le quote del singolo marcatore
export const filterCheckEvento = (state, evento, quota_id=0) => {

  // controllo il campionato
  var check_filter_evento = true
  var campionati_visualizzati = []
  var campionato_check = true
  if (state.campionati.length > 0) {
    campionati_visualizzati = getCampionatiVisualizzati(state.campionati);
    campionato_check = campionati_visualizzati.includes(evento.campionato_id)
  }
  
  if(check_filter_evento && !campionato_check){
    check_filter_evento = false
  }


  // controllo il testo di ricerca
  if(check_filter_evento && state.filtro_evento){
    check_filter_evento = evento.descrizione.toUpperCase().includes(state.filtro_evento.toUpperCase())
  }


  // controllo il testo di ricerca del marcatore per vedere se visualizzare l'evento
  if(check_filter_evento && state.filtro_marcatore){
    check_filter_evento = false
    evento.marcatori.forEach(marcatore => {
      if(!check_filter_evento)
        check_filter_evento = marcatore.quota_descrizione.toUpperCase().includes(state.filtro_marcatore.toUpperCase())
    })
  }

  // controlla la data
  if(check_filter_evento && state.filtro_data != null){
    var dates = getFilterDatesByType(state.filtro_data);
    check_filter_evento = (
      (
        (
          evento.timestamp >= dates.start_date 
          && evento.timestamp<= dates.end_date
        ) 
        || dates.get_all
      ) 
    );

  }
  
  // inizio il controllo sulle quote 
  if(check_filter_evento){
    var check_marcatore = !state.alert_eye
    var index_marcatore = -1
    var concessionari_da_non_considerare = getConcessionariNonVisualizzati(state.concessionari);
    var specialita_visualizzate = getSpecialitaAttive(state.specialita)
    var concessionari_avvisi_visibili = getConcessionariAvvisoVisible(state.concessionari);

    // controllo gli alert per la singola quota id
    if(Object.keys(state.quote).length !== 0){
      if(evento.evento_id in state.quote){
        if(quota_id && quota_id in state.quote[evento.evento_id]){
          state.concessionari.forEach(concessionario => {
            if(!concessionari_da_non_considerare.includes(concessionario.concessionario_codice)){
              if(!check_marcatore){
                index_marcatore = evento.marcatori.findIndex(function(competitor) {
                  return Object.keys(competitor.specialita).find(key => parseInt(competitor.specialita[key]) === quota_id)
                });
                Object.keys(evento.marcatori[index_marcatore].specialita).map((specialita_id) => {
                  if(!check_marcatore){
                    check_marcatore = 
                    (
                      evento.marcatori[index_marcatore].specialita[specialita_id] in state.quote[evento.evento_id]
                      && 
                      concessionario.concessionario_codice in state.quote[evento.evento_id][evento.marcatori[index_marcatore].specialita[specialita_id]]
                      &&
                      'alert' in state.quote[evento.evento_id][evento.marcatori[index_marcatore].specialita[specialita_id]][concessionario.concessionario_codice] 
                      &&
                      specialita_visualizzate.includes(parseInt(specialita_id))
                      &&
                      concessionari_avvisi_visibili.includes(concessionario.concessionario_codice)
                      &&
                      (
                        (((state.quote[evento.evento_id][evento.marcatori[index_marcatore].specialita[specialita_id]][concessionario.concessionario_codice]['alert'] & MarcatoriConst.VALUE_ALERT_BF) === MarcatoriConst.VALUE_ALERT_BF) && state.alert_attivi['BF']['abilitato']) 
                        || 
                        (((state.quote[evento.evento_id][evento.marcatori[index_marcatore].specialita[specialita_id]][concessionario.concessionario_codice]['alert'] & MarcatoriConst.VALUE_ALERT_AVG) === MarcatoriConst.VALUE_ALERT_AVG) && state.alert_attivi['AVG']['abilitato'])
                      )
                    ) 
                  }
                })
                // check_marcatore = (
                //   'alert' in state.quote[evento.evento_id][quota_id][concessionario.concessionario_codice] 
                //   &&
                //   (
                //     (state.quote[evento.evento_id][quota_id][concessionario.concessionario_codice]['alert'] & MarcatoriConst.VALUE_ALERT_BF && state.alert_attivi['BF']['abilitato']) 
                //     || 
                //     (state.quote[evento.evento_id][quota_id][concessionario.concessionario_codice]['alert'] & MarcatoriConst.VALUE_ALERT_AVG && state.alert_attivi['AVG']['abilitato'])
                //   ) 
                // )
              }
            }
          })
          // recupero l'indice del marcatore scorrendo l'array presente nell'evento conoscendo la quota_id
          index_marcatore = evento.marcatori.findIndex(function(marcatore) {
            return Object.keys(marcatore.specialita).find(key => parseInt(marcatore.specialita[key]) === quota_id)
          });
          if(index_marcatore>=0) {
            evento.marcatori[index_marcatore].visible = Boolean(check_marcatore)
          }
        } else {
          // controllo tutti i singoli marcatori
          evento.marcatori.forEach(marcatore => {
            if(marcatore.enabled){
              check_marcatore = !state.alert_eye
              // prendo tutte le quote id relative ai marcatori
              Object.keys(marcatore.specialita).map(key_specialita => {
                if (specialita_visualizzate.includes(parseInt(key_specialita))){
                  var temp_quota_id = marcatore.specialita[key_specialita]
                  if(temp_quota_id in state.quote[evento.evento_id]){
                    Object.keys(state.quote[evento.evento_id][temp_quota_id]).map(concessionario_codice => {
                      if(!concessionari_da_non_considerare.includes(concessionario_codice)){
                        if(!check_marcatore){                    
                          check_marcatore = (
                            'alert' in state.quote[evento.evento_id][temp_quota_id][concessionario_codice]
                            &&
                            concessionari_avvisi_visibili.includes(concessionario_codice)
                            &&
                            (
                              (((state.quote[evento.evento_id][temp_quota_id][concessionario_codice]['alert'] & MarcatoriConst.VALUE_ALERT_BF) === MarcatoriConst.VALUE_ALERT_BF) && state.alert_attivi['BF']['abilitato']) 
                              || 
                              (((state.quote[evento.evento_id][temp_quota_id][concessionario_codice]['alert'] & MarcatoriConst.VALUE_ALERT_AVG) === MarcatoriConst.VALUE_ALERT_AVG) && state.alert_attivi['AVG']['abilitato'])
                            )
                          )
                        }
                      }
                    })
                  }
                }
              })
            }else{
              check_marcatore = marcatore.enabled
            }
            marcatore.visible = Boolean(check_marcatore)
          })
        }
        //controlla se l'evento ha almeno un marcatore visualizzato, altrimenti nasconde l'evento stesso
        check_filter_evento = false
        var enabled_marcatore = false
        evento.marcatori.forEach(marcatore => {
          if(marcatore.enabled){
            enabled_marcatore = true
            return
          }
        })
        if(enabled_marcatore){
          evento.marcatori.forEach(marcatore => {
            if(marcatore.visible){
              check_filter_evento = true
              return
            }
          })
        }
      }else{
        check_filter_evento = !state.alert_eye
      }
    }
    // setto a false tutti gli altri marcatori che non rispettano il filtro 
    if(state.filtro_marcatore){
      evento.marcatori.forEach(marcatore => {
        if(marcatore.visible)
          marcatore.visible = marcatore.quota_descrizione.toUpperCase().includes(state.filtro_marcatore.toUpperCase())
      })
    }
  }
  evento.visible = check_filter_evento
  return evento
  
}


export const getHeighRowEventoMarcatore = (evento, tipo_visualizzazione, concessionari) =>{
  var result_height = 0

  if(evento.enabled && evento.visible){
    if (evento.open){
      var concessionari_visibili = 0
      var height_concessionario = 19.69 // uguale per tutte le visualizzazioni

      concessionari.forEach(concessionario => {
        if(concessionario.visible)
          concessionari_visibili ++;
      })


      var height_visualization_marcatore_closed = 0
      var height_visualization_marcatore_open = 0
      var height_visualization_riga = MarcatoriConst.ALTEZZA_RIGA_EVENTO

      var total_height_visualization_marcatori = 0
      var total_height_visualization_marcatori_aperti = 0
      var total_height_visualization_marcatori_chiusi = 0
      var numero_marcatori_aperti = 0
      var numero_marcatori_chiusi = 0
      var marcatori_riga = 0
      var marcatori_visibili = 0
      var last_height = 0
      var resto = 0

      if(tipo_visualizzazione === 0){
        height_visualization_marcatore_closed = MarcatoriConst.ALTEZZA_RIGA_MARCATORE_1_COLONNA_CHIUSA
        height_visualization_marcatore_open = (concessionari_visibili * height_concessionario) + MarcatoriConst.ALTEZZA_INTESTAZIONE_SPECIALITA
        marcatori_riga = 1
      }else if (tipo_visualizzazione & 1){
        height_visualization_marcatore_closed = MarcatoriConst.ALTEZZA_RIGA_MARCATORE_1_COLONNA_CHIUSA
        height_visualization_marcatore_open = (concessionari_visibili * height_concessionario) + MarcatoriConst.ALTEZZA_INTESTAZIONE_SPECIALITA
        marcatori_riga = 2
      }else{
        marcatori_riga = 3
        height_visualization_marcatore_closed = MarcatoriConst.ALTEZZA_RIGA_MARCATORE_1_COLONNA_CHIUSA
        height_visualization_marcatore_open = (concessionari_visibili * height_concessionario) + MarcatoriConst.ALTEZZA_INTESTAZIONE_SPECIALITA
      }
      var index_save_height = 0
      var max_height = 0
      evento.marcatori.forEach((marcatore)=>{
        if (marcatore.enabled && marcatore.visible){
          // indice che permette di arrivare al conteggio del numero di marcatori per riga
          index_save_height ++;
          if(marcatore.open){
            // total_height_visualization_marcatori = total_height_visualization_marcatori + height_visualization_marcatore_open
            max_height = height_visualization_marcatore_open
            // total_height_visualization_marcatori_aperti = total_height_visualization_marcatori_aperti + height_visualization_marcatore_open
            last_height = height_visualization_marcatore_open
            // numero_marcatori_aperti ++
          }else{
            if(max_height === 0){
              max_height = height_visualization_marcatore_closed;
            }
            // total_height_visualization_marcatori = total_height_visualization_marcatori + height_visualization_marcatore_closed 
            //total_height_visualization_marcatori_chiusi = total_height_visualization_marcatori_chiusi + height_visualization_marcatore_closed 
            last_height = height_visualization_marcatore_closed
            //numero_marcatori_chiusi ++
          }
          if(index_save_height === marcatori_riga){
            total_height_visualization_marcatori = total_height_visualization_marcatori + max_height;
            index_save_height = 0
            max_height = 0
          }
          marcatori_visibili ++
        }
      })
      resto = marcatori_visibili%marcatori_riga  
      if(resto === 0){
        // total_height_visualization_marcatori = (total_height_visualization_marcatori_aperti*marcatori_riga + total_height_visualization_marcatori_chiusi)/marcatori_riga
        result_height = height_visualization_riga + total_height_visualization_marcatori
      }else{
        // total_height_visualization_marcatori = (total_height_visualization_marcatori_aperti*marcatori_riga + total_height_visualization_marcatori_chiusi - resto*last_height)/marcatori_riga
        result_height = height_visualization_riga + total_height_visualization_marcatori + last_height;
      }
    }else{
      result_height = MarcatoriConst.ALTEZZA_RIGA_EVENTO
    }
  }
  return result_height
}

export const getLocalState = () => {
  var result = {}
  var local_state = window.localStorage.getItem("STATE_APP")
  if(local_state){
    result = JSON.parse(local_state)
  }

  // controllo la presenza di tutte le chiavi che doverbbero esserci nello stato
  // nel caso in cui non le trovassi le aggiungo con valori di default
  if(!('impostazioni' in result)){
    result.impostazioni = {}
  }
  if(!('distanza' in result.impostazioni)){
    result.impostazioni.distanza = 0
  }
  if(!('percentuale' in result.impostazioni)){
    result.impostazioni.percentuale = 30
  }
  if(!('alert_abilitati' in result.impostazioni)){
    result.impostazioni.alert_abilitati = {
      'BF':{
        'abilitato': true,
        'colore': 'bg-danger',
        'descrizione': 'Alert BETFAIR'
      }, 
      'AVG': {
        'abilitato': true,
        'colore': 'bg-warning', 
        'descrizione': 'Alert Quota Media'
      }
    }
  }
  if(!('limite_quota_media' in result.impostazioni)){
    result.impostazioni.limite_quota_media = 1000
  }
  if(!('concessionari' in result.impostazioni)){
    result.impostazioni.concessionari = {}
  }
  if(!('specialita' in result.impostazioni)){
    result.impostazioni.specialita = {}
  }
  if(!('riduci_intestazioni_specialita' in result.impostazioni)){
    result.impostazioni.riduci_intestazioni_specialita = false
  }
  return result;
}

export const saveLocalState = (state) => {
  // salvataggio dello stato dei concessionari costruendomi un dizionario che avrà come chiave i concessionari
  let statoConcessionari = {} 
  state.concessionari.forEach(concessionario => {
    statoConcessionari[concessionario.concessionario_codice] = {
      visible: concessionario.visible,
      valuta_quota_media: concessionario.valuta_quota_media,
      avvisi_visible: concessionario.avvisi_visible,
    }
  })

  // salvataggio dello stato delle specialità costruendomi un dizionario che avrà come chiave le specialità
  let statoSpecialita = {}
  state.specialita.forEach(specialita => {
    statoSpecialita[specialita.id] = {
      visible: specialita.visible,
    }
  })
  var result = {
    impostazioni: {
      distanza: state.distance,
      limite_quota_media: state.limite_quota_media,
      percentuale: state.percentuale_avg,
      alert_abilitati: state.alert_attivi,
      concessionari: statoConcessionari,
      specialita: statoSpecialita,
      riduci_intestazioni_specialita: state.riduci_intestazioni_specialita
    }
  }
  window.localStorage.setItem("STATE_APP", JSON.stringify(result));
}

export const inizializzaEventoCampi = (evento) => {
  var tempMarcatori= []
  var tempEvento = {}
  var marc = {}
  evento.marcatori.forEach(marcatore => {
    marc = {
      ...marcatore, 
      visible: true,
      enabled: true,
      open: true

    }
    tempMarcatori.push(marc)  
  })

  tempEvento = {
    ...evento,
    visible: true,
    enabled: true,
    open: true,
    marcatori: tempMarcatori,
    quote_errore: false,
  }

  return tempEvento
}


// dato gli eventi e il filtro per data utilizzo (oggi, domani o tutti), ritorna l'insieme dei
// campionati che degli eventi che soddisfano il filtro
// se viene indicato il parametro 'campionati_id_presenti', aggiunge solo i campionati che non sono già presenti
// 'check_campionati_presenti è un array che indica i campionati presenti ma che viene utilizzato dopo la creazione dei campionati per verificare
// se è necessario ricopiarsi le impostazioni presenti prima'
export const campionato_generaelenco = (eventi, filtro_data, campionati_presenti = [], campionati_id_presenti = [], check_campionati_presenti = []) =>
{
	var tempCampionati = [...campionati_presenti];
	var campionatiInseriti = [...campionati_id_presenti];

  var check_filter_evento;
  var dates = getFilterDatesByType(filtro_data);

	eventi.forEach(evento => {	
    if(!campionatiInseriti.includes(evento.campionato_id)) {
      check_filter_evento = (
        (
        (
          evento.timestamp >= dates.start_date 
          && evento.timestamp<= dates.end_date
        ) 
        || dates.get_all
        ) 
      );

      if(check_filter_evento){
        var campionato = {}
        campionato['campionato_id'] = evento.campionato_id
        campionato['campionato_descrizione'] = evento.campionato_descrizione
        campionato['visible'] = true
        tempCampionati.push(campionato)
        campionatiInseriti.push(campionato['campionato_id'])
      }
    }
	})
  // nel caso in cui fosse passata questa variabile, sovrascrivo i campionati che sono stati creati
  // con quelli già presenti se ci sono salvando le impostazioni precedenti
  if(check_campionati_presenti.length > 0){
    tempCampionati.forEach((campionato, index) => {
      var campionato_presente = check_campionati_presenti.find(function(campionato_presente) {
        return campionato_presente.campionato_id === campionato.campionato_id
      })
      if(campionato_presente !== undefined){
        tempCampionati[index] = campionato_presente
      }
    })
  }
  // ordinamento per nome dei campionati
	tempCampionati.sort((a, b) => (a.campionato_descrizione > b.campionato_descrizione) ? 1 : -1)  
  return tempCampionati
}

export const waitTimeCancellazioneEventi = (eventi) => {
  // funzione che ritorna il tempo di attesa per la cancellazione degli eventi
  // direttamente in millisecondi
    let primoEvento = null
    let delayDifferenza = 5 // numero di secondi utili per evitare di richiamare la funzione di cancellazione degli eventi troppo presto
    eventi.forEach((evento)=>{
      if(primoEvento === null){
        primoEvento = evento
      }else{
        if(evento.timestamp < primoEvento.timestamp){
          primoEvento = evento
        }
      }
    })
    // differenza timestamp tra il timestamp del primo evento e il timestamp attuale
    if(primoEvento !== null){
      // questo calcolo ritorna la differenza in secondi
      let differenza = primoEvento.timestamp - (((new Date().getTime())/1000) - (new Date().getTimezoneOffset() * 60)) + delayDifferenza
      if(differenza < 0){
        differenza = 1
      }
      return differenza * 1000 
    }
    return null
  }
