export const MarcatoriActionsType = {
    ADD_CONCESSIONARI: '@@marcatori/ADD_CONCESSIONARI',
    GET_CONCESSIONARI: '@@marcatori/GET_CONCESSIONARI',
    CHANGE_VISUALIZATION_CONCESSIONARIO:'@@marcatori/CHANGE_VISUALIZATION_CONCESSIONARIO',
    CHANGE_VISUALIZATION_SPECIALITA: '@@marcatori/CHANGE_VISUALIZATION_SPECIALITA',
    ADD_EVENTI: '@@marcatori/ADD_EVENTI',
    ADD_EVENTO: '@@marcatori/ADD_EVENTO',
    GET_EVENTI: '@@marcatori/GET_EVENTI',
    GET_EVENTO: '@@marcatori/GET_EVENTO',
    ADD_QUOTE: '@@marcatori/ADD_QUOTE',
    GET_QUOTE_EVENTO: '@@marcatori/GET_QUOTE_EVENTO',
    SET_QUOTA: '@@marcatori/SET_QUOTA',
    LOAD_QUOTE: '@@marcatori/LOAD_QUOTE',
    SET_QUOTA_EVENTO: '@@marcatori/SET_QUOTA_EVENTO',
    ADD_SPECIALITA: '@@marcatori/ADD_SPECIALITA',
    GET_SPECIALITA: '@@marcatori/GET_SPECIALITA',
    SET_TOKEN: '@@marcatori/SET_TOKEN',
    SET_CAMPIONATI: '@@marcatori/SET_CAMPIONATI',
    CHANGE_VISUALIZATION_EVENTI: '@@marcatori/CHANGE_VISUALIZATION_EVENTI',
    SET_DISTANCE: '@@marcatori/SET_DISTANCE',
    CHECK_ALERT_DISTANCE: '@@marcatori/CHECK_ALERT_DISTANCE',
    SET_PERCENTUALE: '@@marcatori/SET_PERCENTUALE',
    CHECK_ALERT_AVG_PERCENTUALE: '@@marcatori/CHECK_ALERT_AVG_PERCENTUALE',
    CHECK_EVENT_ALERT_AVG_PERCENTUALE: '@@marcatori/CHECK_EVENT_ALERT_AVG_PERCENTUALE',
    CHECK_MARCATORE_SPECIALITA_ALERT_AVG_PERCENTUALE: '@@marcatori/CHECK_MARCATORE_SPECIALITA_ALERT_AVG_PERCENTUALE',
    CHANGE_STATE_ALERT_SOUND: '@@marcatori/CHANGE_STATE_ALERT_SOUND',
    DELETE_STARTED_EVENT: '@@marcatori/DELETE_STARTED_EVENT',
    SET_ENABLED_STATE_EVENT: '@@marcatori/CHANGE_ENABLED_STATE_EVENT',
    SET_ENABLED_STATE_PLAYER: '@@marcatori/SET_ENABLED_STATE_PLAYER',
    SET_EYE_STATE: '@@marcatori/SET_EYE_STATE',
    SET_ALERT_ABILITATI: '@@marcatori/SET_ALERT_ABILITATI',
    SET_FILTRO_TIPO_DATA: '@@marcatori/SET_FILTRO_TIPO_DATA',
    SET_FILTRO_EVENTO_NAME: '@@marcatori/SET_FILTRO_EVENTO_NAME',
    SET_REF_LIST: '@@marcatori/SET_REF_LIST',
    SET_OPEN_STATE_MARCATORE: '@@marcatori/SET_OPEN_STATE_MARCATORE',
    ALL_SET_OPEN_STATE_MARCATORE: '@@marcatori/ALL_SET_OPEN_STATE_MARCATORE',
    SET_OPEN_STATE_EVENTO: '@@marcatori/SET_OPEN_STATE_EVENTO',
    ALL_SET_OPEN_STATE_EVENTO: '@@marcatori/ALL_SET_OPEN_STATE_EVENTO',
    UPDATE_LIST: '@@marcatori/UPDATE_LIST',
    RESIZE_LIST: '@@marcatori/RESIZE_LIST',
    SAVE_IMPOSTAZIONI: '@@marcatori/SAVE_IMPOSTAZIONI',
    SET_FILTRO_MARCATORE: '@@marcatori/SET_FILTRO_MARCATORE',
    CHANGE_TIPO_ORDINAMENTO: '@@marcatori/CHANGE_TIPO_ORDINAMENTO',
    CHANGE_VISUALIZATION_ALL_EVENTI: '@@marcatori/CHANGE_VISUALIZATION_ALL_EVENTI',
    SET_QUOTA_DESCRIZIONE: '@@marcatori/SET_QUOTA_DESCRIZIONE',
    SET_LIMITE_QUOTA_MEDIA: '@@marcatori/SET_LIMITE_QUOTA_MEDIA',
    CHANGE_VALUTA_QUOTA_MEDIA_CONCESSIONARIO: '@@marcatori/CHANGE_VALUTA_QUOTA_MEDIA_CONCESSIONARIO',

    REMOVE_ALERT: '@@marcatori/REMOVE_ALERT', // azione che rimuove l'alert dalla barra, tramite cestino
    SORT_ALERT_BAR_INFO: '@@live/SORT_ALERT_BAR_INFO', // azione usata per settare le info riguardanti il sort degli alert
    SORT_ALERT_BAR: '@@live/SORT_ALERT_BAR', // azione usata per modificare l'ordine degli alert in base al sort configurato

    // azione per modificare lo stato della visualizzazion degli avvisi sul concessionario
    CHANGE_VISUALIZATION_AVVISI_CONCESSIONARIO : "@@marcatori/CHANGE_VISUALIZATION_AVVISI_CONCESSIONARIO",
    
    // azione per modificare lo stato del ridimensionamento delle intestazioni collegate alle specialità
    CHANGE_RIDUCI_INTESTAZIONI_SPECIALITA: "@@marcatori/CHANGE_RIDUCI_INTESTAZIONI_SPECIALITA"
};

// Enum per identificare le informazioni inerenti al sorting degli alert
export const alertBarInfoEnum = Object.freeze({
    PERCENTUALE_GUADAGNO: 0,
    TIMESTAMP: 1,
    
    CRESCENTE: 2,
    DECRESCENTE: 3
  });
